<template>
    <div>
                <Pane />

    <a-card class="container">

<div class="card-header">
        <div class="card-title">项目信息</div>
</div>

          <a-descriptions bordered :column="2" size='small'>
      <a-descriptions-item >
        <div slot="label" class="center">设计编号</div>
        <div>
          {{ detail.designCode }}
        </div>
      </a-descriptions-item>
            <a-descriptions-item >
        <div slot="label" class="center">合同名称</div>
        <div>
          {{ detail.name }}
        </div>
      </a-descriptions-item>
            <a-descriptions-item >
        <div slot="label" class="center">类别</div>
        <div>
                  <span v-if="detail.type === 'selfOper'">自营类</span>
              <span v-if="detail.type === 'admin'">管理类</span>
        </div>
      </a-descriptions-item>
            <a-descriptions-item >
        <div slot="label" class="center">合同金额(元)</div>
        <div style="color: #1890ff">
          {{ detail.contractAmount ? detail.contractAmount.toLocaleString() : '--' }}
        </div>
      </a-descriptions-item>
    </a-descriptions>


<div class="card-header">
        <div class="card-title">投标利润</div>
</div>

   <a-table
        bordered
        :data-source="[...bidList, bidAll]"
        :loading="loading"
        :pagination="false"
      >
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === bidList.length ? '合计' : text.num }}
                </template>
            </a-table-column>

        <a-table-column title="类别" align='center'>
          <template slot-scope="text">
            {{text.cate }}
          </template>
        </a-table-column>


        <a-table-column title="拟投标价(元)" align='right' >
          <template slot-scope="text">
              <span style="color: #1890ff">
                {{typeof text.preBidPrice === 'number' ? text.preBidPrice.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>
                <a-table-column title="承担主体" >
         <template slot-scope="text">
               <span>
                {{text.respSubject}}
              </span>
          </template>
        </a-table-column>

        <a-table-column title="投标控制预算(元)"  align='right'>
          <template slot-scope="text">
                        <span style="color: #1890ff">
                {{typeof text.bidBudget === 'number' ? text.bidBudget.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>
                <a-table-column title="投标利润(元)" align='right' >
          <template slot-scope="text">
                         <span style="color: #1890ff">
                {{typeof text.bidProfit === 'number' ? text.bidProfit.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>
        
        <a-table-column title="备注">
         <template slot-scope="text">
                       <span>
                {{text.remark}}
              </span>
          </template>
        </a-table-column>

      </a-table>

        <div class="card-header">
        <div class="card-title">实施控制预算利润</div>
</div>

         <a-table
        bordered
          :data-source="[...bidBudgetList, budgetAll]"
        :loading="loading"
        :pagination="false"
        rowKey="num"
      >

            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === bidBudgetList.length  ? '合计' : text.num }}
                </template>
            </a-table-column>

        <a-table-column title="类别" align='center'>
            <template slot-scope="text">
                          <span >
                            {{text.cate}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="合同价(元)"  >
          <template slot-scope="text">
                                     <span style="color: #1890ff">
                {{typeof text.contractPrice === 'number' ? text.contractPrice.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="承担主体" >
         <template slot-scope="text">
               <span >
                {{text.respSubject}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="实施控制预算(元)"  >
          <template slot-scope="text">
     
                                                  <span style="color: #1890ff">
                {{typeof text.impBidBudget === 'number' ? text.impBidBudget.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="目标利润(元)"  >
          <template slot-scope="text">
                                                                <span style="color: #1890ff">
                {{typeof text.targetProfit === 'number' ? text.targetProfit.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="实际利润(元)"  >
          <template slot-scope="text">
                                                                              <span style="color: #1890ff">
                {{typeof text.actualProfit === 'number' ? text.actualProfit.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                <a-table-column title="备注">
         <template slot-scope="text">
                       <span >
                        {{text.remark}}
              </span>
          </template>
        </a-table-column>
   
      </a-table>


      <div class="center" style="margin-top: 80px;padding-bottom: 80px">
        <a-space>
               <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
      
    </a-card>
    </div>
</template>



<script>

import request from '@/api/request'

function fetchDetail(params) {
    return request({
        url:  '/market-service/epc/view/profit/queryById',
        params,
    })
}

export default {

    data() {
        return {
            loading: false,
            detail: {},
            bidList: [],
            bidBudgetList: [],

        }
    },

    computed: {
      bidAll() {
        let preBidPrice = 0;
        let bidBudget = 0;
        let bidProfit = 0;
        this.bidList.forEach(item => {
            if(item.num.indexOf('.') > 0) {
              return;
            }
            if (typeof item.preBidPrice === 'number') {
              preBidPrice += item.preBidPrice;
            }
               if (typeof item.bidBudget === 'number') {
              bidBudget += item.bidBudget;
            }
               if (typeof item.bidProfit === 'number') {
              bidProfit += item.bidProfit;
            }
        });
        return {
          preBidPrice,
          bidBudget,
          bidProfit,
        }
      },
      budgetAll() {
            let contractPrice = 0;
        let impBidBudget = 0;
        let targetProfit = 0;
        let actualProfit = 0;

        this.bidBudgetList.forEach(item => {
            if(item.num.indexOf('.') > 0) {
              return;
            }      
            if (typeof item.contractPrice === 'number') {
              contractPrice += item.contractPrice;
            }
               if (typeof item.impBidBudget === 'number') {
              impBidBudget += item.impBidBudget;
            }
               if (typeof item.targetProfit === 'number') {
              targetProfit += item.targetProfit;
            }
                  if (typeof item.actualProfit === 'number') {
              actualProfit += item.actualProfit;
            }
        });

        return {
          num: '#',
          contractPrice,
          impBidBudget,
          targetProfit,
          actualProfit
        }
      }
    },

    mounted() {
        const { id } = this.$route.query;

        this.loading = true;
        fetchDetail({
            id,
        }).then(res => {
          if (res) {
            this.detail = res;
            this.bidList = res.bidList || []
            this.bidBudgetList = res.bidBudgetList || []
          }
        }).finally(() => {
          this.loading = false;
        })
    },


}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.card-header {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-title {
  font-weight: bold;
  font-size: 14px;
  color: #1890ff;
  }

  
}

</style>